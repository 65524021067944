import { NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import { FINANCIAL_OTHERS_INPUTS } from './financialOthersRental'
import { actions } from './financial'

export const schema = [
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'annuity',
        label: 'Lijfrente',
        placeholder: 'Lijfrente',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      ...FINANCIAL_OTHERS_INPUTS
    ]
  }
]

export default {
  heading: 'Andere financiële parameters (te koop)',
  schema,
  actions
}
